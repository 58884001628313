.top-nav {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background: linear-gradient(45deg, #ff9900 25%, #006e65 25%);
	color: #FFF;
	height: 75px;
	padding: 1em;
	z-index: 999;
	
}

.menu {
	display: flex;
	flex-direction: row;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.menu>li {
	margin: 0 1rem;
	overflow: hidden;
}

a {
	color: #fff;
}

.div-nav {
	width: 20%;
}

.menu-button-container {
	display: none;
	height: 100%;
	width: 30px;
	cursor: pointer;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.logo-img {
	width: 50%;
	margin-left: 25%;
	margin-top: 3%;
}

#menu-toggle {
	display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
	display: block;
	background-color: rgb(255, 255, 255);
	position: absolute;
	height: 4px;
	width: 30px;
	transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
	border-radius: 2px;
	z-index: 999;
}

.menu-button::before {
	content: '';
	margin-top: -8px;
}

.menu-button::after {
	content: '';
	margin-top: 8px;
}

#menu-toggle:checked+.menu-button-container .menu-button::before {
	margin-top: 0px;
	transform: rotate(405deg);
}

#menu-toggle:checked+.menu-button-container .menu-button {
	background: #487969;
}

#menu-toggle:checked+.menu-button-container .menu-button::after {
	margin-top: 0px;
	transform: rotate(-405deg);
}

@media (max-width: 700px) {
	.top-nav {
		background: linear-gradient(45deg, #ff9900 50%, #006e65 50%);
	}

	.menu-button-container {
		display: flex;
	}

	.menu {
		position: absolute;
		top: 0;
		margin-top: 50px;
		left: 0;
		flex-direction: column;
		width: 100%;
		justify-content: center;
		align-items: center;
		z-index: 999;
	}

	.logo-img {
		width: 70%;
		margin-left: 0%;
	}

	.div-nav {
		width: 45%;
	}

	#menu-toggle~.menu li {
		height: 0;
		margin: 0;
		padding: 0;
		border: 0;
		transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
	}

	#menu-toggle:checked~.menu li {
		border: 1px solid #333;
		height: 2.5em;
		padding: 0.5em;
		transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
	}

	.menu>li {
		display: flex;
		justify-content: center;
		margin: 0;
		padding: 0.5em 0;
		width: 100%;
		background-color: #fff;
	}

	a {
		color: #006e65;
	}

	.menu>li:not(:last-child) {
		border-bottom: 1px solid #444;
	}
}

@media (max-width: 400px) {
	.logo-img {
		width: 85%;
	}

	.div-nav {
		width: 50%;

	}

}