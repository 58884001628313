.h1p{
    font-size: 2.5rem;
    color: #000;
    background-color: rgba($color: #fff, $alpha: 0.7);
 
}


a:hover {
    color: #000;
}
.container-fluid {
    height: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
}
.cart {
    background: rgb(255, 255, 255);
    padding: 35px;
    border-radius: 20px;
    box-shadow: 0 0 15px 2px rgba(0,0,0,0.5);
    margin-top: 3%;
    margin-bottom: 5%;
    margin-left: 5%;
    text-align: justify;
    max-width: 90%;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img-style{
  width: 100%;
  display: block;
  margin: auto;
}

ul {
  list-style-type: none;
}

li {
  display: inline-block;
  margin-right: 25px;
}
.li-prod{
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0);
  transition: 0.3s all ease;
    &.yellow {
    background-color: #eac278;
  }
  &.black {
    background-color: #1f241c;
  }
  &.pinky {
    background-color: #cb7a73;
  }
  &.brown {
    background-color: #80746e;
  }
  &.natural {
    background-color: #a7a49c;
  }
  &.ladrillo {
    background-color: #b68657;
  }
}

.yellow:hover,
.black:hover,
.pinky:hover, .brown:hover, .natural:hover ,.ladrillo:hover {
  border: 2px solid #000;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}

.product-colors .active:after{
  content: "";
  width: 30px;
  height: 30px;
  border: 2px solid #000;
  position: absolute;
  border-radius: 50%;
  box-sizing: border-box;
  left: -5px;
  top: -5px;
}


.colors {
    display: flex;
    padding: 5px;
    border-radius: 20px;
    align-items: center;
    height: 40px;
    justify-content: center;
}

.product-title {
    font-size: 35px;
    font-weight: 700;
    color: #000;
}

.custom-btn {
    background: #ce7705;
    color: #fff;
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    border-radius: 25px;
    padding: 10px;
    transition: 0.3s;
    font-weight: 500;
    margin-top: 10%;
}
.custom-btn:hover {
    text-decoration: none;
    color: #fff;
    opacity: 0.88;
}

.img-carrusel{
  width: 400px;
  display: block;
  margin: auto;
}



@media only screen and (max-width: 768px){
    .product-title {
        margin-top: 65px;
    }
    .h1p{
      font-size: 2rem;
    }
    
    .cart {
        padding: 25px;
        text-align: justify;
        
    }
    * {
        text-align: center;
    }
    .img-style{
      width: 40%;
    }
    .tabs-content{
      margin-top: 3%;
    }
    .row{
      display: flex;
      flex-direction: column;
      align-items: center;
    }

}

@media only screen and (max-width: 550px){
  .cart {
    max-width: 70%;
    margin-left: 15%;
  }
}

@media only screen and (max-width: 440px){
  li {
    margin-right: 15px;
  }
  .li-prod{
    content: "";
    width: 20px;
    height: 20px;
  }
  .cart {
    max-width: 60%;
    margin-left: 20%;
  }
  
}
@media only screen and (max-width: 390px){
    .cart {
        padding: 15px;
    }
    .h1p{
      margin-top: 20%;
      font-size: 1.7rem;
    }
    
    .img-style{
      width: 80%;
    }
    
}

@media only screen and (max-width:400px){
  .cart {
    max-width: 50%;
    margin-left: 25%;
  }
}

@media only screen and (max-width: 300px){
  .cart {
    max-width: 40%;
    margin-left: 30%;
  }
}

//resp navbar
@media only screen and (max-width: 1360px){
  
  .icon-cards{
    margin-top: 20%;
}
}
@media only screen and (max-width: 1080px){
  .icon-cards{
    margin-top: 25%;
}
}

@media only screen and (max-width: 700px){
  .icon-cards{
    margin-top: 30%;
}
}
@media only screen and (max-width: 600px){
  .icon-cards{
    margin-top: 35%;
}
}
@media only screen and (max-width: 470px){
  .icon-cards{
    margin-top: 40%;
}
}
@media only screen and (max-width: 410px){
  .icon-cards{
    margin-top: 50%;
}
}

@media only screen and (max-width: 350px){
  .icon-cards{
    margin-top: 55%;
}
}
@media only screen and (max-width:  290px){
  .icon-cards{
    margin-top: 65%;
}
}