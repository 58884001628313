
.principal{
  display: flex;
  justify-content: center;
}
.principal-r{
  display: flex;
  flex-direction:row; 
  width: 100%;
  align-items: center;
}
.div-par{
  display: flex;
  flex-direction:row;
  align-items: center;
}
.div-dis{
  width: 17vw;
  background-color: #255c58;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  padding: 10px;
  text-align: center;
  color: white;
  margin-top: 5%;
  margin-left: 6%;
}
.img-div-dis{
  width: 40%;
  height: auto;
  display: block;
  margin: auto;
}
.img-of{
  width: 70%;
  display: block;
  margin: auto;
  height: auto;
  border-radius: 50%;
}
.section-mar {
  margin-top: 0%;
}

.div-dis-of{
  width: 30%;
  padding: 10px;
  height: max-content;
  text-align: center;
  color: rgb(255, 255, 255);
  margin: 50px 5% 20px 5%;
}
.div-of{
  background-color: #2a3b36;
  color: white;
  margin-top: 100px;
}
.img-blog-inicio{
  width: 50%;
  display: block;
  margin: auto;
  margin-bottom: 30px;
}

.div-dis-blog{
  width: 25vw;
  background-color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  color: #000;
  margin: 50px 20px 0px 20px;
  
}

// Colors
$color-one: #969ca1;
$color-two: #0048ff;
$color-three: #c79406;
$color-four: #F6B352;

*, *:before, *:after {
  box-sizing: border-box;
}
.h1cont{
  font-size: 2rem;
  color:#487969 ;
  margin-top: 17%;
}
.hd{
  font-size: 1.8rem;
  color: rgb(255, 255, 255);
  margin-top: 50px;
}
.h1b{
  font-size: 2rem;
  color:#2a3b36 ;
  margin-top: 80px;
}
.card {
  background: #565f54;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  margin: 50px 20px 20px 20px;
  width: calc(25% ); 
  padding: 20px;
  text-align: center;
  color: white;
  float: left;
  @media (max-width: 768px) {
    margin: 10px 20px;
    width: calc(100% - 40px);
    margin-top: 20px;
  }
  .title {
    font-size: 25px;
  }
  .features {
    ul {
      padding: 0;
      margin: 20px 0 50px 0;
      list-style-type: none;
      li {
        margin: 10px 0;
        font-size: 14px;
        span {
          border-bottom: 2px dotted #F6B352;
        }
      }
    }
  }

  .card_logo{
    margin: 0;
  width: 15vw;
  height: auto;
  }
  .btn {
    display: block;
    background: $color-four;
    color: white;
    padding: 15px 20px;
    margin: 20px 0;
    border-radius: 5px;
    box-shadow: rgba(0,0,0,0.9);
    transition: all 200ms ease-in-out;
    text-decoration: none;
    &:hover {
      background: #c79406;
    }
  }
}


@media only screen and (max-width: 768px){
  .principal{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .div-dis-of{
    width: 50%;
    text-align: justify;
    margin-left: 5%;
    margin-right: 5%
  }
  .img-of{
    width: 60%;
  }
  .div-dis-blog{
    width: 90%;
    margin-top:3%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .div-dis{
    width: 40%;
    height: max-content;
  }
 
  .h1b, .h1cont, .hd, .h1r{
    font-size: 1.3rem;
  }
  .p-cont{
    font-size: 1rem;
    text-align: center;
  }
  .img-blog-inicio{
    width: 25%;
  }

}
@media only screen and (max-width: 600px){
  .div-par{
    width: 100%;
    display: flex;
    flex-direction:row;
    margin-left:20%;
    margin-right: 20%;
  }
  .principal-r{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .div-dis{
    width: 50vw;
    height: max-content;
  }
  .p-cont{
    font-size: 0.8rem;
    text-align: center;
  }
  
 
}


@media only screen and (max-width:  850px){
  .section-mar {
    margin-top: 16%;
}
}
@media only screen and (max-width: 470px){
  .h1cont{
    margin-top: 40%;
}
.section-mar {
  margin-top: 20%;
}
}