@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@import './mixins';
@import './settings';
@import 'components/footer.scss';
@import 'components/navbar.scss';
@import 'components/contenido.scss';
@import 'components/blog.scss';
@import 'components/contacto.scss';
@import 'components/nosotros.scss';
@import 'components/producto.scss';
@import 'components/adoquin.scss';
@import 'components/working.scss';



html {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  scroll-behavior: smooth;
}


*,
*::after,
*::before {
  box-sizing: inherit;
  list-style: none;
  text-decoration: none;
}

body {
  margin: 0;
  overflow-x: hidden;
}

ul,
ol {
  padding: 0px;
}

a:visited,
a:link,
a:focus,
a:hover,
a:active {
  list-style: none;
  text-decoration: none;
}
