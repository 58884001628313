.imgn-blog {
    margin: auto;
    display: block;
    width: 80vw;

}

.centrado {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-family: 'Roboto';
    font-size: 3rem;
    font-weight: 600;
    text-align: center;

    &.p {
        top: 60%;
        font-size: 1.5rem;
        font-weight: 500;
        text-align: center;
    }
}

.principal-blog {
    display: flex;
    justify-content: center;
}

.div-blog {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.margen-blog {
    margin-top: 5%;
}

.card-blog {
    background: #527468;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    margin: 50px 20px 20px 20px;
    width: calc(30%);
    padding: 20px;
    text-align: center;
    color: white;
    float: left;

    @media (max-width: 768px) {
        margin: 10px 20px;
        width: calc(100% - 40px);
        margin-top: 20px;
    }

    .title {
        font-size: 25px;
    }

    .features {
        ul {
            padding: 0;
            margin: 20px 0 50px 0;
            list-style-type: none;

            li {
                margin: 10px 0;
                font-size: 14px;

                span {
                    border-bottom: 2px dotted #c77d15;
                }
            }
        }
    }
}


.div-blogs {
    width: 40vw;
    background-color: #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    color: #000;
    margin: 50px 20px 20px 20px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.h1blog {
    margin-top: 70px;
    color: #487969;
    font-family: 'Roboto';

}

.contenido-blog {
    margin-left: 10%;
    //margin-top: 50px;
    margin-right: 10%;
    text-align: justify;
    align-items: center;
}

.blogimg {
    margin-top: 5%;
    margin-left: calc(25%);
    width: 50%;
    display: block;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}

.fecha-blog {
    font-size: 1rem;
    text-align: start;
}
.texto-blog {
    font-size: 1rem;
    text-align: justify;
}

@media only screen and (max-width: 1500px) {
    .centrado {
        font-size: 2.5rem;
        top: 22.5%;

        &.p {
            font-size: 1rem;
            top: 50%;
        }
    }
    
}

@media only screen and (max-width: 1200px) {
    .centrado {
        font-size: 2.2rem;
        top: 20%;

        &.p {
            font-size: 1rem;
            top: 50%;
        }
    }

}

@media only screen and (max-width: 950px) {
    .centrado {
        font-size: 2rem;
        top: 17.5%;

        &.p {
            font-size: 1rem;
            top: 50%;
        }
    }
}

@media only screen and (max-width: 800px) {
    .centrado {
        font-size: 1.5rem;
        top: 17.5%;

        &.p {
            font-size: 1rem;
            top: 45%;
        }
    }
    
}

@media only screen and (max-width: 700px) {
    .centrado {
        font-size: 1.3rem;
        top: 16%;

        &.p {
            font-size: .8rem;
            top: 45%;
        }
    }
    
}

@media only screen and (max-width: 520px) {
    .centrado {
        font-size: 1rem;
        top: 15%;

        &.p {
            font-size: .6rem;
            top: 40%;
        }
    }
    .fecha-blog {
        font-size: .8rem;
        text-align: start;
    }
    .texto-blog {
        font-size: .8rem;
        text-align: justify;
    }
}

@media only screen and (max-width: 400px) {
    .centrado {
        font-size: .8rem;
        top: 13.5%;

        &.p {
            font-size: .4rem;
            top: 39%;
        }
    }
    .fecha-blog {
        font-size: .7rem;
        text-align: start;
    }
    .texto-blog {
        font-size: .7rem;
        text-align: justify;
    }
}

@media only screen and (max-width: 290px) {
    .centrado {
        font-size: .5rem;
        top: 12.5%;

        &.p {
            font-size: .2rem;
            top: 36%;
        }
    }
    .fecha-blog {
        font-size: .5rem;
        text-align: start;
    }
    .texto-blog {
        font-size: .5rem;
        text-align: justify;
    }
}

@media only screen and (max-width: 1800px) {
    .margen-blog {
        margin-top: 17%;
    }
}

@media only screen and (max-width: 1360px) {

    .margen-blog {
        margin-top: 20%;
    }
}

@media only screen and (max-width: 1080px) {
    .margen-blog {
        margin-top: 25%;
    }
}

@media only screen and (max-width: 700px) {
    .margen-blog {
        margin-top: 30%;
    }
}

@media only screen and (max-width: 600px) {
    .margen-blog {
        margin-top: 35%;
    }
}

@media only screen and (max-width: 470px) {
    .margen-blog {
        margin-top: 40%;
    }
}

@media only screen and (max-width: 410px) {
    .margen-blog {
        margin-top: 50%;
    }
}

@media only screen and (max-width: 350px) {
    .margen-blog {
        margin-top: 55%;
    }
}

@media only screen and (max-width: 290px) {
    .margen-blog {
        margin-top: 65%;
    }
}