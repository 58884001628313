.productos-h1{
    font-size: 2rem;
    color:#487969;
    margin-top: 10%;
}

.productos-div{
  margin-top: 10%;
}
.principal{
    display: flex;
    justify-content: center;
  }
  .card-producto{
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    margin: 50px 20px 20px 20px;
    min-height: 70%;
    width: calc(25% ); 
    padding: 20px;
    text-align: center;
    font-weight: 600;
    color: #487969;
    float: left;
    @media (max-width: 768px) {
      margin: 10px 20px;
      width: calc(100% - 40px);
      margin-top: 20px;
    }
    .title {
      font-size: 25px;
      margin-bottom: 10px;
    }
    p{
        color: #000;
    }
    .features {
      ul {
        padding: 0;
        margin: 20px 0 50px 0;
        list-style-type: none;
        li {
          margin: 10px 0;
          font-size: 14px;
          span {
            border-bottom: 2px dotted #F6B352;
          }
        }
      }
    }
}

.card_logo-img{
    margin: 0;
  width: 15vw;
  margin-top: 3%;
  }
  .btn {
    display: block;
    //position: relative;
    bottom: 0%;
    background: $color-four;
    color: white;
    padding: 15px 20px;
    margin: 20px 0;
    border-radius: 5px;
    box-shadow: rgba(0,0,0,0.9);
    transition: all 200ms ease-in-out;
    text-decoration: none;
    &:hover {
      background: #c79406;
    }
  }

  .img-style{
    margin: 20px 20px 20px 20px;
  }

  @media only screen and (max-width:  850px){
    .productos-h1 {
      margin-top: 20%;
  }
  .productos-div{
    margin-top: 20%;
  }
  }
  @media only screen and (max-width:  400px){
    .productos-h1 {
      margin-top: 25%;
      font-size: 1.8rem;
  }
  .productos-div{
    margin-top: 28%;
  }
  }