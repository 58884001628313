.form {
  background: rgba($color: #696969, $alpha: 1.0);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  width: calc(40%);
  text-align: left;
  margin-top: 30px;
  align-items: center;
  color: white;
  font-size: 1rem;
  float: left;
  padding: 1.5em;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0;

  @media screen and (min-width: 768px) {
    padding: 2.5em;
  }
}

.formF {
  background: rgba($color: #696969, $alpha: 1.0);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  width: calc(40%);
  height: 700px;
  margin-top: 30px;
  float: right;
  padding: 1.5em;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0;

  @media screen and (min-width: 768px) {
    padding: 2.5em;
  }
}

.label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.3rem;
  margin-top: 10px;
}

.textarea-div {
  width: 100%;
}

.textarea {
  padding: 10px;
  width: 100%;
  outline: 0;
  margin-bottom: 20px;
  border-radius: 20px;
  border: 0;
  font-size: 14px;
  height: 20px;
}

.btnform {
  background-color: #249889;
  padding: 12px 15px;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-top: 10px;
  transition: 0.3s all ease;
  color: white;

  &:hover {
    background-color: #006b6b;
  }
}

.section-size {
  height: 900px;
}

.mapouter,
.gmap_canvas,
.frame {
  width: calc(95%);
  height: calc(100%);
}


.div-map {
  margin-left: 7%;
  width: calc(100%);
  height: calc(90%);
  margin-top: 0px;
  float: left;
  overflow: hidden;
  position: relative;
  outline: none;
}

.h1-contact {
  font-family: 'Poppins', sans-serif, 'arial';
  font-weight: 600;
  font-size: 50px;
  margin-top: 10%;
  color: #000000;
  text-align: center;
}

.h1-contact-form {
  font-family: 'Poppins', sans-serif, 'arial';
  font-size: 40px;
  color: #000000;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 30px;
}

.h4-contact {
  font-family: 'Poppins', sans-serif, 'arial';
  font-weight: 600;
  font-size: 20px;
  color: #273631;
  text-align: center;
}

/* ///// inputs /////*/

input:focus~label,
textarea:focus~label,
input:valid~label,
textarea:valid~label {
  font-size: 1rem;
  color: rgb(255, 255, 255);
  top: -5px;
  -webkit-transition: all 0.225s ease;
  transition: all 0.225s ease;
}

.styled-input {
  float: left;
  width: 100%;
  margin: 1rem 0;
  position: relative;
  border-radius: 4px;
  margin-right: 5px;
  margin-left: 5px;
}

.div-principal {
  display: flex;
  justify-content: center;
}

.div-dis-img {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 20px rgba(109, 109, 109, 0.4);
  width: 19vw;
  padding: 10px;
  font-weight: 600;
  text-align: center;
  color: #000;
  margin: 50px 20px 20px 20px;
}

.img-cont {
  width: 30%;
  margin: 10px 0px 10px 0px;
}

@media only screen and (max-width: 768px) {
  .styled-input {
    width: 45%;
  }

  .div-principal {
    align-items: flex-start;
  }

  .section-size {
    height: 1150px;
  }

  .formF {
    width: 80%;
    height: 350px;
    margin-right: 10%;
    margin-top: 50px;
  }

  .div-dis-img {
    font-size: 10px;
    font-weight: 600;
    height: max-content;
  }

  .form {
    margin-left: 10%;
    font-size: 0.5rem;
    width: 80%;
  }

  .h1-contact,
  .h1-contact-form {
    font-size: 30px;
  }

  .h4-contact {
    font-size: 15px;
  }

  .submit-btn {
    width: 100%;
    float: none;
    text-align: center;
  }

  .place-btn {
    width: 45%;
    float: none;
    text-align: center;
    font-size: 12px;
  }
}

@media only screen and (max-width: 1360px) {
  .styled-input {
    width: 100%;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 290px) {
  .section-size {
    height: 1250px;
  }
}

@media only screen and (max-width: 650px) {
  .form {
    width: calc(80%);
    font-size: 20px;
    margin-left: 10%;
  }

  .div-principal {
    display: flex;
    flex-direction: column;
  }

  .div-dis-img {
    font-size: 15px;
    font-weight: 600;
    width: calc(50%);
    margin: 10px 25% 5% 25%;
    height: max-content;
  }

  .h1-contact-form {
    font-size: 20px;
    color: #ffffff;
  }

  .place-btn {
    width: 50%;
    float: none;
    text-align: center;
    font-size: 12px;
  }
}

.styled-input label {
  color: rgb(105, 105, 105);
  padding: 1.3rem 30px 1rem 30px;
  position: absolute;
  top: 10px;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
}

.styled-input.wide {
  width: 800px;
  max-width: 100%;
}

input,
textarea {
  padding: 30px;
  border: 0;
  width: 100%;
  font-size: 1rem;
  background-color: #ffffff;
  color: #000000;
  border-radius: 4px;
}

input:focus,
textarea:focus {
  outline: 0;
}

input:focus~span,
textarea:focus~span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease;
}

textarea {
  width: 100%;
  min-height: 10em;
}

.input-container {
  width: 650px;
  max-width: 100%;
  margin: 20px auto 25px auto;
}

.submit-btn {
  float: right;
  padding: 7px 35px;
  border-radius: 60px;
  display: inline-block;
  background-color: #487969;
  color: white;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06),
    0 2px 10px 0 rgba(0, 0, 0, 0.07);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.place-btn {
  padding: 7px 35px;
  border-radius: 60px;
  width: 100%;
  margin-top: 10px;
  display: inline-block;
  background-color: #d1931e;
  color: white;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06),
    0 2px 10px 0 rgba(0, 0, 0, 0.07);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease
}

.a_cont {
  color: #ffffff;
}

.submit-btn:hover {
  transform: translateY(1px);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.10),
    0 1px 1px 0 rgba(0, 0, 0, 0.09);
}

@media only screen and (max-width:  850px){
  .h1-contact {
    margin-top: 20%;
}
}
@media only screen and (max-width:  400px){
  .h1-contact {
    margin-top: 25%;
    font-size: 1.8rem;
}
}