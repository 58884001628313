.ct {
	background-color: cyan;
}

.ac {
	text-align: center;
}

.container {
	width: 100%;
	color: white;
	background-image: url(../../assets/recursos/working.webp);
	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
	
	& h1 {
		text-transform: uppercase;
		font-size: 5rem;
		margin: 2.5rem;
		letter-spacing: 0.8rem;
		margin-top: 2rem
	}

	& h2 {
		margin-top: 2rem;
		font-size: 1.6rem;
		font-weight: 700;
		font-style: italic;
	}

	& img {
		margin-top: 2rem;
		width: 35%;
	}

	& .count {
		margin-top: 3rem;

		& .countd {
			display: inline-block;
			width: 6.5rem;
			height: 6.5rem;
			border: 2px solid;
			border-radius: var(--round);
			margin: 0 2.5rem 2.5rem;
			overflow: hidden;
			text-transform: uppercase;

			& span {
				display: block;
				font-size: 2rem;
				margin-top: 1.6rem;
			}
		}
	}
}

@media screen and (max-width: 575px) {
	.countd {
		margin: 0 4.6rem 4.6rem !important;
	}

	.container {
		& img {
			margin-top: 4rem;
			width: 45%
		}
	}

}

@media screen and (max-width: 430px) {
	.countd {
		margin: 0 2.5rem 2.5rem !important;
	}
}