*,
*::before,
*::after {
  box-sizing: border-box;
}

.timeline {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 15px 0;
  display: flex;
}

.containerA {
  padding: 15px 30px;
  position: relative;
  background: inherit;
  width: 75%;
  justify-items: center;
}

.containerA .date {
  position: absolute;
  display: inline-block;
  top: calc(50% - 8px);
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #f58402;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 1;
}

.containerA .content {
  padding: 30px 30px 30px 30px;
  background: #487969;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  position: relative;
  border-radius: 20px 20px 20px 20px;
  text-align: center;
}

.containerA.right .content {
  padding: 30px 30px 30px 30px;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

}

.containerA .content h2 {
  margin: 0 0 10px 0;
  font-size: 22px;
  font-weight: 550;
  color: #edc044;
}

.containerA .content p {
  margin: 0;
  font-size: 18px;
  line-height: 22px;
  font-weight:300;
  color: #fff;
  text-align: justify;
}

@media (max-width: 700px) {
  .timeline::after {
    left: 0px;
  }

  .containerA {
    width: 100%;
    padding-right: 30px;
    
  }

  .containerA.right {
    left: 0%;
  }

  .containerA.left {
    left: 0%;
  }

  .containerA.left::after,
  .containerA.right::after {
    left: 0px;
  }

  .containerA.left::before,
  .containerA.right::before {
    left: 100px;
    border-color: transparent #fff transparent transparent;
  }

  .containerA.left .date,
  .containerA.right .date {
    right: auto;
  }


  .containerA.left .content,
  .containerA.right .content {
    padding: 30px 30px 30px 30px;
    border-radius: 50px 50px 50px 50px;
  }

  .about-title {
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 0.8rem;
    font-family: $font;
  }
}

.about-title {
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 2rem;
  height: 10vh;
  margin-top: 2%;
}

.img-carrusel {
  width: 30%;
  display: block;
  margin: auto;
}


.body_sec {
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
}

.div-valores{
  width: 17vw;
  background-color: #fff;
  box-shadow: 0 0 20px #487969;
  padding: 10px;
  text-align: center;
  color: black;
  font-weight:600;
  margin-top: 5%;
  margin-left: 6%;
}


@media only screen and (max-width:  850px){
  .about-title {
    margin-top: 17%;
    font-size: 1.8rem;
}
.div-valores{
  width: 50%;
  height: max-content;
}
}
@media only screen and (max-width:  550px){
  .about-title {
    margin-top: 20%;
    font-size: 1.5rem;
}
.timeline{
  display: table-row;
}
.div-valores{
  width: 60%;
  height: max-content;
}
}
@media only screen and (max-width:  335px){
  .about-title {
    margin-top: 22%;
    font-size: 1.2rem;
}
}
@media only screen and (max-width:  290px){
  .about-title {
    margin-top: 30%;
    font-size: 1.5rem;
}
}